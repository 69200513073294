<template>
    <div class="page-rooter-fff">
        <nav-bar header-title="免费领取训练计划"  backColor="#ffffff" :show-back="true" :header-back="newAppBack" />
        <app-page id="box" class="page-img">
            <img  @click="jump(item.id, item.jumpUrl)" v-for="(item, index) in list" :key="index" :src="item.img" alt="">
        </app-page>
    </div>
</template>

<script>
import {newAppBack, initBack, gotoCampExclusiveDetail, defaultApp} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';

export default {
    components: {
        navBar,
        appPage,
    },
    mixins: [userMixin],
    data() {
        return {
            list: [
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/free-train/1.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/free-train/2.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/free-train/3.jpg'},
                {jumpUrl: 'https://superdeer.wjx.cn/vm/QRON3BS.aspx#', img: 'https://img.chaolu.com.cn/ACT/free-train/4.jpg'},
            ]
        };
    },
    methods: {
        newAppBack,
        jump(id, jumpUrl) {
            // id && this.countPoint('49', '315', id)
            if(this.appTypeStr === 'mini' && jumpUrl){
                this.$router.push("/emptyPage?isUndo=1")
            }else if(jumpUrl){
                defaultApp();
                window.location.href = jumpUrl
                return false
            }
        },
    },
    async created() {
        initBack();
        await this.$getUserId();
        // this.countPoint('49', '315', 1567)
    },
};
</script>

<style scoped lang="less">
.page-rooter-fff{
    background-color: #F9FAFB;
    .page-img{
        img{
            width: 100%;
            display: block;
        }
    }
}
</style>
